const year = document.querySelector<HTMLSpanElement>("#copyright");
const loc = document.location;

if (year) {
  const date = new Date().getFullYear();
  year.innerHTML = date.toString();
}

if (loc.pathname === "/") {
  const footerArda = document.querySelector(".footer .footer-arda");
  footerArda.classList.add("show");
}
