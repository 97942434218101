const flipCard = document.querySelectorAll(".flip-card");

flipCard.forEach((card) => {
  card.addEventListener("mousedown", () => {
    card.classList.add("active");
  });

  card.addEventListener("mouseup", () => {
    card.classList.remove("active");
  });
});
