const scrollToTopBtn = document.querySelector(".scroll-to-top");

var rootElement = document.documentElement;

const scrollHandler = () => {
  const scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
  if (rootElement.scrollTop / scrollTotal > 0.2) {
    // Show button
    scrollToTopBtn?.classList.add("scroll-to-top--show");
  } else {
    // Hide button
    scrollToTopBtn?.classList.remove("scroll-to-top--show");
  }
};

const scrollToTop = () => {
  rootElement.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

document.addEventListener("scroll", scrollHandler);
scrollToTopBtn?.addEventListener("click", scrollToTop);
