const gifsCallback: IntersectionObserverCallback = (entries) => {
  for (let i = 0; i < entries.length; i++) {
    const src = entries[i].target.getAttribute("data-src");
    (entries[i].target as HTMLImageElement).src =
      entries[i].isIntersecting && src ? src : "";
  }
};
const gifs = document.querySelectorAll<HTMLImageElement>(".gif");
const observer = new IntersectionObserver(gifsCallback);
for (let i = 0; i < gifs.length; i++) {
  observer.observe(gifs[i]);
}
