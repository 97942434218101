const burgerBtn = document.querySelector(".burger-menu-icon");
const mobileMenu = document.querySelector(".burger-menu-content");
const body = document.querySelector("body");
const contactButton = document.querySelector(".header-wrapper-contact-button");
const tabLinks = document.querySelectorAll(".navigation a");

const toggleMobileMenu = () => {
  if (mobileMenu?.classList.contains("open")) {
    mobileMenu.classList.remove("open");
    mobileMenu.classList.add("close");
    body.style.overflow = "auto";
  } else {
    mobileMenu?.classList.toggle("open");
    body.style.overflow = "hidden";
  }
  burgerBtn?.classList.toggle("open");
};

document.addEventListener("animationend", (e) => {
  if (e.animationName === "anim-fade") {
    mobileMenu?.classList.remove("close");
  }
});

const calculateVh = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};
window.addEventListener("resize", () => {
  calculateVh();
});

calculateVh();

burgerBtn?.addEventListener("click", toggleMobileMenu);

contactButton?.addEventListener("click", openModal);

const activePage = location.href;
tabLinks?.forEach((link) => {
  if (link.href === activePage) {
    link.classList.add("active");
  }
});
