document.addEventListener("DOMContentLoaded", () => {
  const form = document.getElementById("feedbackModal-form") as HTMLFormElement;
  const modal = document.querySelector(".modal");
  const toast = document.querySelector(".toast");
  const overlay = modal.querySelector(".overlay");
  const loader = modal.querySelector(".loader");

  const name = form.querySelector("input[name='name']");
  const email = form.querySelector("input[name='email']");
  const agreement = modal?.querySelector("#modalAgreement");
  const submitButton = modal?.querySelector(".form-container-button");

  if (agreement && submitButton) {
    agreement.addEventListener("click", (e) => {
      if (e.target.checked) {
        submitButton.disabled = false;
      } else {
        submitButton.disabled = true;
      }
    });
  }

  window.sendModalForm = function () {
    let isNameValid = checkField(name),
      isConnectValid = checkField(email);

    let isFormValid = isNameValid && isConnectValid;

    if (isFormValid) {
      overlay.classList.add("active");
      loader.classList.add("active");
      sendDataToGoogleSheets(form, closeModal, () => showToast(toast));
      console.log("sending data...");
    }
  };

  form.addEventListener("input", formInputChecker);

  const contactButton = document.querySelector(".title-contact-button");
  const collapseButton = document.querySelector(".collapse-button");
  const collapseBlock = document.querySelector(".form-container-collapse");
  const closeButton = document.querySelector(".modal-exitButton");
  const select = document.querySelectorAll(".select");
  const body = document.querySelector("body");

  function closeModal() {
    form.reset();
    [name, email].forEach((input) => showSuccess(input));
    modal.classList.remove("modal_visible");
    body.style.overflow = "auto";
    overlay.classList.remove("active");
    loader.classList.remove("active");
    collapse(collapseBlock, "remove");
    document
      .querySelectorAll(".select button")
      .forEach((el) => el.classList.remove("active"));
  }

  contactButton?.addEventListener("click", openModal);

  closeButton.addEventListener("click", closeModal);

  collapseButton.addEventListener("click", (e) => {
    e.preventDefault();
    collapse(collapseBlock, "toggle");
  });

  select.forEach((el: HTMLDivElement) => {
    document.addEventListener("click", (e) => {
      const isClickInside = el.contains(e.target);

      if (!isClickInside) {
        el.classList.remove("active");
      }
    });

    const elInput: HTMLInputElement = el.querySelector(".select-input");
    const resetSelect = el.querySelector(".select-cancel-btn");

    resetSelect.addEventListener("click", (e) => {
      elInput.value = "";
      resetSelect.classList.remove("active");
    });

    el.addEventListener("click", (e) => {
      if (e.target.nodeName === "BUTTON") {
        return;
      }

      el.classList.toggle("active");
    });

    el.querySelectorAll(".select-option").forEach((option) => {
      option.addEventListener("click", (e) => {
        elInput.value = e.target.outerText;
        resetSelect.classList.add("active");
      });
    });
  });
});
